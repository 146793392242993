import { Box, Code, List, Stack, Title } from '@mantine/core';
import { useWindowEvent } from '@mantine/hooks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type Action = {
  keyCode: string;
  label: string;
}

const actions: Action[] = [
  {
    keyCode: 'Space',
    label: 'Start',
  },
  {
    keyCode: 'Space',
    label: 'Koš do vody',
  },
  {
    keyCode: 'Space',
    label: 'Voda na stroji',
  },
  {
    keyCode: 'Space',
    label: 'Výstřik',
  },
]

const finalActions: Action[] = [
  {
    keyCode: 'ArrowLeft',
    label: 'Sražení terče L',
  },
  {
    keyCode: 'ArrowRight',
    label: 'Sražení terče P',
  }
]

function App() {
  const [start, setStart] = useState<DateTime>();
  const [nextActionKey, setNextActionKey] = useState(0);
  const [actionsTime, setActionsTime] = useState<(DateTime|undefined)[]>(actions.map(() => undefined))
  const [, setIterator] = useState(0);
  const [int, setInt] = useState<NodeJS.Timer>();
  const [ends, setEnds] = useState<(DateTime|undefined)[]>(finalActions.map(() => undefined));


  useWindowEvent('keydown', (event) => {
    if(!start) {
    if(event.code === actions[0].keyCode) {
      const now = DateTime.now()
      setStart(now)
      setInt(setInterval(() => setIterator(prevState=>prevState+1), 10))
      setActionsTime(prevState => {
        const newState = [...prevState]
        newState[0] = now
        return newState
      })
      setNextActionKey(1)
    }
  } else {
    if(nextActionKey < actions.length && !ends.every(end => end !== undefined)) {
    if(event.code === actions[nextActionKey].keyCode) {
      setActionsTime(prevState => {
        const newState = [...prevState]
        newState[nextActionKey] = DateTime.now()
        return newState
      })
      setNextActionKey(prevState => prevState+1)
    }
  }

      finalActions.forEach((action, key) => {
        if(event.code === action.keyCode) {
          if(ends[key] !== undefined) {
            return
          }
          setEnds(prevState => {
            const newState = [...prevState]
            newState[key] = DateTime.now()
            return newState
          })
          //clearInterval(int!)
        }
      })
    }
    if(event.code === 'KeyR') {
      setStart(undefined)
      setNextActionKey(0)
      setActionsTime(actions.map(() => undefined))
      setEnds(finalActions.map(() => undefined))
      clearInterval(int!)
    }
  }, { capture: true });

  useEffect(() => {
    //check if both ends have filled values, if so, clear interval
    if(ends.length === finalActions.length && ends.every(end => end !== undefined)) {
      clearInterval(int!)
    }
  }, [ends, int])

  return (
    <Stack>
      <Box>
        {start ? 
        <Title order={1}>Čas: <Time start={start} end={DateTime.now()} /></Title>
        : <Title order={1}>Čekáme na start</Title>}
      </Box>
      <Box>
        {ends.map((end, key) => {
          if(end === undefined) {
            return null
          }
          return (
            <Title order={1} key={key}>{finalActions[key].label}: <Time start={start} end={end} /></Title>
          );
        })}
      </Box>
    <List>
      {actions.map((action, key) => {
        return (<List.Item>{action.label}: <Time start={start} end={actionsTime[key]} /></List.Item>);
      })}
      {finalActions.map((action) => (<List.Item>{action.label}</List.Item>))}
    </List>
    <List>
      <List.Item><Code>Mezerník</Code>: Start/Zaznamenat čas</List.Item>
      <List.Item><Code>Šipka doleva</Code>: Sražení terče L</List.Item>
      <List.Item><Code>Šipka doprava</Code>: Sražení terče P</List.Item>
      <List.Item><Code>R</Code>: Reset</List.Item>
    </List>
    </Stack>
  );
}

const Time = ({start, end}: {start: DateTime|undefined, end: DateTime|undefined}) => {
  if(!start || !end) {
    return null
  }
  const diff = end.diff(start, ["seconds", "milliseconds"])
  return (
    <>{diff.seconds}.{diff.milliseconds.toString().padStart(3, "0")}</>
  )
}

export default App;
